import { faqLinks } from '../constants/links';
import SUBSTITUTION_TYPES from '../utils/substituteLocales/substitutionTypes';

export default {
  commonTexts: {
    savingBlock: {
      text: 'В этом блоке есть несохранённые изменения',
      buttons: {
        save: 'Сохранить',
        cancel: 'Отмена',
      },
    },
    button: {
      save: 'Сохранить',
      cancel: 'Отмена',
      back: 'Назад',
    },
    tableProductsHeader: {
      name: 'Название',
      vendorCode: 'Артикул',
      price: 'Цена',
      action: '',
    },
    multiselect: {
      submitButton: 'Добавить к акции',
      cancelButton: 'Отмена',
      selectAllButton: 'Выбрать все',
      selectNoneButton: 'Отменить выделение',
      products: {
        placeholder: 'Название товара, артикул, уникальный код',
        pageText: 'Страница',
        emptyList: {
          title: 'Нет такого товара',
          description: 'Попробуйте другое название или очистите поиск, чтобы посмотреть все товары',
        },
      },
      categories: {
        placeholder: 'Название категории',
        emptyList: {
          title: 'Нет такой категории',
          description:
            'Попробуйте другое название или очистите поиск, чтобы посмотреть все категории',
        },
      },
    },
  },
  validation: {
    required: 'Обязательно для заполнения',
    coupon: {
      required: 'Код купона не может быть пустым ',
      alreadyExist: 'Код используется',
    },
  },
  indexPage: {
    bundlesAreaTitle: 'Комплекты магазина',
    bundlesAreaSubtitle:
      'Вы можете редактировать и удалять комплекты, а также временно отключать предложения и включать обратно',
    pageTitle: 'Комплекты',
    addLabelAreaTitle: 'Привлеките внимание к товарам',
    addLabelAreaSubtitle:
      'Объединяйте товары в наборы со скидкой, чтобы Покупатель отправлял в корзину сразу несколько позиций и тратил больше денег в вашем магазине',
    faqAreaTitle: 'FAQ',
    faqAreaSubtitle: {
      html: 'Особенности работы приложения, которые необходимо учитывать при его использовании. Если у вас возникнут вопросы или трудности - <a href="https://simcase-apps.omnidesk.ru/?send=question" target="_blank">свяжитесь с нами</a>, мы обязательно поможем',
    },
    faq: {
      question1: {
        title: 'Promo Item Header',
        content:
          'Advertise and sell your products directly through your page and reach billions of potential customers. Advertise and sell your products directly through your page and reach billions of potential customers.',
      },
      question2: {
        title: 'Promo Item Header',
        content:
          'Advertise and sell your products directly through your page and reach billions of potential customers. Advertise and sell your products directly through your page and reach billions of potential customers.',
      },
    },
    iconableBlockTitle: 'Собрать комплект',
    iconableBlockDescription:
      'Создавайте комплекты, объединенные общей идеей: наборы к праздникам или комплементарные товары. Подскажите Покупателю, что дополнит его корзину, и подкрепите предложение заманчивой скидкой',
    addNewBundle: 'Добавить комплект',
    card: {
      action: 'Действия',
      actionUpdate: 'Редактировать',
      actionDelete: 'Удалить',
    },
    removeBundleModal: {
      label: 'Вы действительно хотите удалить комплект?',
      agreeButton: 'Да',
      cancelButton: 'Нет',
    },
  },
  homePage: {
    title: 'BOGOF: акции вида «Два по цене одного»',
    addToShopArea: {
      title: 'Новая акция',
      subtitle:
        'Выберите товары, которые участвуют в акции, и определите вознаграждение для покупателя – скидку на определенные товары или подарок при покупке',
      interactiveBlock: {
        title: 'Увеличьте средний чек',
        subtitle:
          'Управляйте поведением покупателей: предложите клиентам выбрать определенные товары и получить другие со скидкой или бесплатно. Такие акции – проверенный способ увеличить количество товаров в чеке!',
        buttonText: 'Настроить акцию',
      },
    },
    promotionsArea: {
      title: 'Управление акциями',
      subtitle:
        'Вы можете легко включать и выключать каждую BOGOF-акцию, а также менять размер и условия скидки',
      interactiveBlock: {
        actionButton: {
          title: 'Действия',
          actionsList: {
            edit: 'Редактировать',
            delete: 'Удалить',
          },
        },
      },
      dialogDelete: {
        title: 'Вы действительно хотите удалить акцию ?',
        submitButton: 'Да',
        cancelButton: 'Нет',
      },
    },
    shopRevisionArea: {
      title: 'Доработайте магазин под ваши нужды',
      subtitle:
        'Иногда просто скидки недостаточно: нужно подсказать покупателю, какой товар еще добавить в корзину',
      interactiveBlock: {
        title: 'Ручная настройка товарных рекомендаций',
        subtitle:
          'Помогите клиенту собрать все товары для 1+1=3 и предложите ему аксессуары, сопутствующие товары или аналоги подороже!',
        buttonText: 'Создать товарные рекомендации',
      },
    },
    bannerAreaTheFirst: {
      title: 'Cобирайте товары в наборы и продавайте больше',
      subtitle:
        'Легко и просто увеличивайте свою прибыль: объедините несколько товаров в набор и продавайте их вместе со скидкой! Создавайте подарочные наборы, объединяйте бестселлеры и менее популярные товары и добавляйте комплекты «Часто покупают вместе». Добавьте это приложение в магазин, чтобы увеличивать продажи и уменьшать расходы на логистику.',
      buttonText: 'Создать комплект',
    },
  },
  detailPromotionPage: {
    title: 'Настройки акции',
    setupPromotionArea: {
      title: 'Параметры акции',
      subtitle: 'Установите размер скидки и добавьте описание акции',
      interactiveBlock: {
        section_1: {
          toggleHint: 'Статус (вкл / выкл)',
        },
        section_2: {
          title_1: 'Акция',
          parameter: {
            title: 'Тип скидки',
            option_1: 'В процентах',
            option_2: 'Фиксированная сумма',
          },
          meaning: {
            title: 'Размер',
            hint_1: '(100% – покупатель получит товар бесплатно)',
          },
          title_2: 'Описание',
          description:
            'Краткое описание акции (до 255 символов) отобразится в корзине, когда покупатель соберет все необходимые для участия товары',
          descriptionPlaceholder: 'Введите описание',
        },
      },
    },
    setupPurchaseArea: {
      title: 'Условия акции',
      subtitle:
        'Определите, какие товары (или товары каких категорий) должен приобрести покупатель. Укажите, какое минимальное количество этих товаров необходимо для участия в акции',
      interactiveBlock: {
        productsAmount: {
          title: 'Количество товаров',
          subtitle:
            'Минимальное количество товаров, которые покупатель должен приобрести для участия в акции',
        },
        productsBinding: {
          title: 'Необходимые для участия в акции товары',
          subtitle:
            'Выберите, какие товары должен приобрести покупатель, чтобы принять участие в акции',
        },
        productCategoriesBinding: {
          title: 'Необходимые для участия в акции категории',
          subtitle:
            'Выберите, товары каких категорий должен приобрести покупатель, чтобы принять участие в акции',
        },
      },
    },
    setupDiscountArea: {
      title: 'Применение скидок',
      subtitle:
        'Определите, какие товары участник акции получит со скидкой. Скидка отобразится после добавления этих товаров в корзину',
      interactiveBlock: {
        productsBinding: {
          title: 'Акционные товары',
          subtitle: 'Выберите товары, которые участник акции сможет получить со скидкой',
        },
        productCategoriesBinding: {
          title: 'Акционные категории товаров',
          subtitle:
            'Выберите, товары из каких категорий участник акции сможет приобрести со скидкой ',
        },
        productManualBinding: {
          title: 'Ручная привязка товаров',
          subtitle: 'Выберите товары, на которые будет активна ссылка',
        },
      },
    },
    setupDisplayPromotionArea: {
      title: 'Механика акции',
      subtitle: 'Выберите, нужен ли специальный купон для активации BOGOF-акции',
      interactiveBlock: {
        title: 'Тип акции',
        subtitle:
          'Выберите автоматический тип скидок, чтобы акция была доступна всем покупателям. Или создайте купон, который покупатель должен будет ввести, чтобы активировать акцию',
        displayTypeSelectBox: {
          option_1: 'Скидка рассчитывается автоматически',
          option_2: 'Требуется купон',
        },
        couponInput: {
          title: 'Добавьте код купона (промо-кода) для активации скидки',
          placeholder: 'буквенно-числовой код купона или слово',
        },
      },
    },
    FAQ_Area: {
      title: 'FAQ',
      subtitle:
        'Особенности работы приложения, которые необходимо учитывать при его использовании. Если у вас возникнут вопросы или трудности – свяжитесь с нами, мы обязательно поможем',
      substitutions: [
        {
          type: SUBSTITUTION_TYPES.LINK,
          name: 'свяжитесь',
          text: 'свяжитесь',
          payload: {
            href: faqLinks.contactUs,
            target: '_blank',
          },
        },
      ],
      whenDiscountApplying: {
        title: 'Когда к акционному товару применяется скидка?',
        description:
          'Покупатели должны добавить в корзину товары и акционные товары. Цена со скидкой отобразится уже в корзине. Пока товары не добавлены в корзину, скидка не будет применяться',
      },
      howDiscountApplying: {
        title:
          'Если в корзине покупателя больше одного товара из категории GET – к какому будет применена скидка?',
        description:
          'Скидка применяется к самому дешевому товару из всех акционных товаров в корзине.',
      },
      discountNotWork: {
        title: 'Почему моя фиксированная скидка не работает?',
        description:
          'Если вы выбираете фиксированный тип скидки, пожалуйста, внимательно проверяйте товары, которые добавляете в список акционных. Их цена должна быть больше, чем размер скидки. Например, если ваша фиксированная скидка – 1000 рублей, а цена самого дешевого акционного товара в корзине 500 рублей, то покупатель получит этот товар бесплатно (скидка составит 500 рублей).',
      },
    },
  },
};
